.interests-title {
    font-size: 52px;
    font-weight: 400;
    line-height: normal;
}

#interests ul li {
    margin-bottom: 20px; // adjust as needed
  }

.link-dark .link-light {
    color: #6f3c9c;
}

@media (max-width: 1380px) {
    .interests-title {
      font-size: 40px;
    }
  }
  @media (max-width: 768px) {
    .interests-title {
      font-size: 30px;
      text-align: center;
    }
    .repo-cards-div-main {
      grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    }
  }